import * as React from 'react'
import { Slot } from '@radix-ui/react-slot'
import { LucideIcon } from "lucide-react";
import { cva, type VariantProps } from 'class-variance-authority'

import { cn } from '@/lib/utils'
import { MouseEventHandler } from 'react';

const buttonVariants = cva(
  'inline-flex items-center justify-center rounded-md text-sm font-medium shadow ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50',
  {
    variants: {
      variant: {
        default:
          'bg-baclbtn text-primary-foreground shadow-md hover:bg-baclbtn/80',
        destructive:
          'bg-destructive text-destructive-foreground hover:bg-destructive/90',
        outline:
          'border border-input hover:bg-accent hover:text-accent-foreground',
        secondary:
          'bg-secondary text-secondary-foreground hover:bg-secondary/80',
        ghost: 'shadow-none hover:bg-accent hover:text-accent-foreground',
        link: 'underline-offset-4 shadow-none hover:underline',
        link2: 'text-primary underline-offset-4 shadow-none hover:underline'
      },
      size: {
        default: 'h-8 px-4 py-2',
        sm: 'h-8 rounded-md px-3',
        lg: 'h-11 rounded-md px-8',
        icon: 'h-8 w-8 p-0'
      }
    },
    defaultVariants: {
      variant: 'default',
      size: 'default'
    }
  }
)

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
  VariantProps<typeof buttonVariants> {
  asChild?: boolean
}


const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : 'button'
    // const compProps = props as React.ButtonHTMLAttributes<HTMLButtonElement | HTMLElement>; // 使用类型断言转换props的类型
    const compProps = props as Omit<ButtonProps, 'contentEditable'>;
    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        {...compProps}
      />
    )
  }
)


// const Button: React.FC<ButtonProps> = ({ className, variant, size, asChild = false, ...props }, ref) => {
//   const Comp = asChild ? Slot : 'button';
//   const compProps = props as Omit<ButtonProps, 'contentEditable'>;
//   return (
//     <Comp
//       className={cn(buttonVariants({ variant, size, className }))}
//       ref={ref}
//       {...compProps}
//     />
//   );
// };
// Button.displayName = 'Button'

export { Button, buttonVariants }





type Props = {
  className?: string;
  IconLeft?: LucideIcon;
  label: string;
  IconRight?: LucideIcon;
  onClick?: MouseEventHandler<any> | undefined;
};

export const PrimaryButton: React.FC<Props> = ({ className, IconLeft, label, IconRight, onClick }) => {
  return (
    <div className="relative group/button">
      <div className="absolute -inset-0.5 bg-gradient-to-r from-[#0239FC] to-[#7002FC] rounded-lg blur-md hover:opacity-75 group-hover/button:opacity-100 transition duration-1000 hover:rotate-20  opacity-0 group-hover/button:duration-200" />
      <button
        type="button"
        className={cn(
          "relative flex items-center px-4 gap-2 text-sm font-semibold text-black bg-white rounded-lg h-10",
          className,
        )}
        onClick={onClick}
      >
        {IconLeft ? <IconLeft className="w-4 h-4" /> : null}
        {label}
        {IconRight ? <IconRight className="w-4 h-4" /> : null}
      </button>
    </div>
  );
};

export const SecondaryButton: React.FC<Props> = ({ className, IconLeft, label, IconRight, onClick }) => {
  return (
    <button
      type="button"
      className={cn(
        "items-center gap-2 px-4 duration-500 text-white/50 hover:text-white h-10 hidden sm:flex",
        className,
      )}
      onClick={onClick}
    >
      {IconLeft ? <IconLeft className="w-4 h-4" /> : null}
      {label}
      {IconRight ? <IconRight className="w-4 h-4" /> : null}
    </button>
  );
};

export const RainbowDarkButton: React.FC<Props> = ({ className, label, IconRight, onClick }) => {
  return (
    <div className={cn("p-[.75px] hero-hiring-gradient rounded-full w-fit mx-auto", className)}>
      <button
        type="button"
        className="items-center gap-4 px-3 py-1.5 bg-black text-white rounded-full flex flex-block text-sm"
        onClick={onClick}
      >
        {/* <SparkleIcon className="text-white" /> */}
        {label}
        {IconRight ? <IconRight className="w-4 h-4" /> : null}
      </button>
    </div>
  );
};
